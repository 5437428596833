//DetailSearch
class DetailSearch {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(".js-all").on('click', function() {
      if($(this).find('input').prop('checked') == true) {
        $(this).siblings().find('input').prop('checked', true);
      } else {
        $(this).siblings().find('input').prop('checked', false);
      }
    })

    $(".js-individual").on('click', function() {
      $(this).siblings('.js-all').find('input').prop('checked', false);
    })

    $('.c-s-sort-wrap__check').each(function(index, el){
      let check_el = $(this).find('.js-individual input').length;
      let check_num = 0;
      $(this).find('.js-individual input').each(function(index, el) {
        if(el.checked) {
          check_num++;
        }
      })

      if(check_el === check_num && check_el != 0) {
        $(this).find('.js-all input').prop('checked', true);
      }
    });

    $('.js-master').click(function() {
      $("[name='master_cat']").val($(this).val());
    })

    $("#anchor-article.js-inview").addClass('is-inview');

    var el_num = $(".c-product-navArea-el").length;

    switch(el_num) {
      case 3:
        $("#anchor-article").addClass('u-mt-sp-17');
        break;

      case 4:
        $("#anchor-article").addClass('u-mt-sp-23');
        break;

      case 5:
        $("#anchor-article").addClass('u-mt-sp-28');
        break;

      default:
        break;
    }
  } 
}
