//noScroll
class ImgZoom {
  constructor(){  
    //imgのIDやクラスを指定
    var $img = $("#zoomImg").imgViewer2(
      {
        //画像の揺れの改善
        onReady: function() {
          this.setZoom(2);
          this.setZoom(1);
        }
      }
    );
  }
}
