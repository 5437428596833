class Faq {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    var el_num = $(".c-product-navArea-el").length;

    switch(el_num) {
      case 3:
        $(".p-faq__container").addClass('u-mt-sp-17');
        break;

      case 4:
        $(".p-faq__container").addClass('u-mt-sp-23');
        break;

      case 5:
        $(".p-faq__container").addClass('u-mt-sp-28');
        break;

      default:
        break;
    }
  }
}
