class Company {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    var img01 = new Swiper(".img01", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next01",
        prevEl: ".prev01",
      },
    });
    var img02 = new Swiper(".img02", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next02",
        prevEl: ".prev02",
      },
    });
    var img03 = new Swiper(".img03", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next03",
        prevEl: ".prev03",
      },
    });
    var img04 = new Swiper(".img04", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next04",
        prevEl: ".prev04",
      },
    });
    var img05 = new Swiper(".img05", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next05",
        prevEl: ".prev05",
      },
    });
  }
}
