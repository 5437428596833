//noScroll
class Accordion {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    /**　親要素にjs-open設置 **/
    $('.p-aCase .c-s-sort-inner').show();
    $('.js-open').click(function(){
			$(this).toggleClass('is-open');
			$(this).next().slideToggle(300);
		});
  }
}
