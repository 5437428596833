//noScroll
class CommonParts {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){

    // ---------------------------
    // PC時 検索
    // ---------------------------

    $(".js-search").on({
      mouseover: function() {
        $(this).find('.gsc-input').addClass('is-active');
      },
      mouseout: function() {
        $(this).find('.gsc-input').removeClass('is-active');
      }
    })

    // ---------------------------
    // SP時 ハンバーガーメニュー 開閉
    // ---------------------------

    const body = document.getElementsByTagName('html')[0];
    const btn = document.querySelector('.js-h-trigger');
    let scrollTop = 0;
    
    btn.addEventListener('click', () => {
      $(".js-header").toggleClass('is-open');
      if (body.classList.contains('is-hidden')) {
        // スクロール開放
        body.style.top = '';
        body.classList.remove('is-hidden');
        window.scrollTo(0, scrollTop);
      } else {
        // スクロール禁止
        scrollTop = window.scrollY;
        body.style.top = (scrollTop * -1) + 'px';
        body.classList.add('is-hidden');
      }
    });

    // ---------------------------
    // SP時 ハンバーガーメニュー中身 アコーディオン
    // ---------------------------

    $(".js-h-aco").click(function() {
      $(this).next().next().slideToggle();
      $(this).next().toggleClass('is-active');
    })

    // ---------------------------
    // PC時 メガメニュー 開閉
    // ---------------------------

    $(".js-h-mega").on({
      mouseover: function() {
        let mega_attr = $(this).data("mega");
        $(`[data-mega=${mega_attr}]`).addClass('is-active');
        if($('.h-mv').length) {
          $('html').addClass('h-mv-mega');
        }
      },
      mouseout: function() {
        let mega_attr = $(this).data("mega");
        $(`[data-mega=${mega_attr}]`).removeClass('is-active');
        if($('.h-mv').length) {
          $('html').removeClass('h-mv-mega');
        }
      }
    })

    let time = null;
    let wTop = $(window)[0].scrollY;
    let hHeight = null;
    let mvHeight = '';
    if($(".p-top .s-mv").length) {
      mvHeight = $(".s-mv")[0].offsetTop + $(".s-mv")[0].offsetHeight;
    }

    $(window).scroll(function() {
      clearTimeout(time);
      time = setTimeout(() => {
        if(wTop - $(window)[0].scrollY >= 0 || $(window)[0].scrollY < 20 ) {
          hHeight = '0%';
          $('html').removeClass('h-hdn');
        } else {
          hHeight = '-100%';
          $('html').addClass('h-hdn');
        }
        $('.js-header__wrap, .l-header-overlay__wrap').css({'transform':`translateY(${hHeight})`});
        wTop = $(window)[0].scrollY;

        if($(".p-top .s-mv").length) {
          if(mvHeight < $(this).scrollTop()) {
            $('html').removeClass('h-mv');
          } else {
            $('html').addClass('h-mv');
          }
        }
      }, 50);
    })

    // ---------------------------
    // サイト内検索 プレースホルダー
    // ---------------------------
    $('.gsc-control-cse input').attr('placeholder', 'サイト内検索')
  }
}
