class Mv {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    let pcsp = '';

    let onlyFuncFlgSpindle = false;
    let onlyFuncFlgMg = false;

    window.matchMedia('(max-width: 767px)').matches ? pcsp = 'sp' : pcsp = 'pc';

    const mvTl = gsap.timeline();

    if(!$('.noLoading').length) {
      mvTl.to('.s-mv__mg-mov', .3, {
        delay: 4.5,
      })
    }


    let spndleStart = () => {
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__mg-mov', .3, {
          opacity: 0,
        })
      } else {
        mvTl.to('.s-mv__mg-mov', .3, {
          opacity: 0,
        }, "-=0.5")
      }

      mvTl.to('.s-mv__circle-bottom', 1.5, {
        rotate: '0deg',
        opacity: 1,
        stagger: .2,
      })
      
      mvTl.to('.s-mv__circle-top', 1.5, {
        rotate: '270deg',
        opacity: 1,
        stagger: .2,
      }, '-=1.5')

      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle img', 3.5, {
          y: '0rem',
          x: '-0rem',
          stagger: .1,
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.072,0.832,0.144,0.966,0.158,0.984,0.174,0.989,0.204,0.99,0.976,1,1,1,1,1"),
        }, '-=1')
      } else {
        mvTl.to('.s-mv__spindle img', 3.5, {
          y: '0',
          x: '-0',
          stagger: .1,
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.072,0.832,0.144,0.966,0.158,0.984,0.174,0.989,0.204,0.99,0.976,1,1,1,1,1"),
        }, '-=1')
      
        mvTl.to('.s-mv__spindle img', .5, {
          opacity: 0,
        }, '-=.5')
      }
      
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle-txt-wrap > *', 3.2, {
          x: '0',
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.034,0.642,0.104,0.832,0.13,0.9,0.164,0.909,0.204,0.914,0.974,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      } else {
        mvTl.to('.s-mv__spindle-txt-wrap > *', 3.2, {
          x: '1rem',
          ease: CustomEase.create("custom", "M0,0,C0,0,0.014,0.354,0.03,0.622,0.03,0.672,0.03,0.672,0.04,0.73,0.058,0.778,0.074,0.794,0.1,0.8,0.984,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      }
      
      mvTl.to('.s-mv__spindle-txt-wrap > *', .3, {
        opacity: 1,
        stagger: .1,
      }, '<')
      
      // if(pcsp == 'pc') {
      //   mvTl.to('.s-mv__spindle-txt-wrap > *', .3, {
      //     opacity: 0,
      //     stagger: .1,
      //   }, '-=.8')
      // }

      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle, .s-mv__spindle-txt', .2, {
          opacity: 0,
        }, '-=.2')

        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '<')
      } else {
        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '-=.3')
      }
        
      
      mvTl.to('.s-mv__circle-top', .3, {
        rotate: '50deg',
        opacity: 0,
        stagger: .2,
      }, '-=.1')
      
      mvTl.to('.s-mv__spindle-mov', .3, {
        opacity: 1,
        onStart: () => {
          $('.s-mv__spindle-mov video').get(0).play();

          // if(onlyFuncFlgSpindle === false) {
            // $('.s-mv__spindle-mov video').on("ended", (e)=> {
            setTimeout(() => {
              if(pcsp == 'pc') {
                mvTl.to('.s-mv__spindle img', .1, {
                  y: '-120rem',
                  x: '120rem',
                  opacity: 1,
                })
              } else {
                mvTl.to('.s-mv__spindle img', .1, {
                  y: '-50rem',
                  x: '50rem',
                  opacity: 1,
                })
              }

              if(pcsp == 'pc') {
                mvTl.to('.s-mv__spindle-txt-wrap > *', .1, {
                  x: '-15rem',
                  opacity: 0,
                })
              } else {
                mvTl.to('.s-mv__spindle-txt-wrap > *', .3, {
                  x: '3rem',
                  opacity: 0,
                })
                mvTl.to('.s-mv__spindle-txt-wrap > *', .1, {
                  x: '-8rem',
                })
              }

              $('.s-mv__spindle, .s-mv__spindle-txt').removeAttr('style');

              onlyFuncFlgSpindle = true;
  
              mgStart();
            }, 7500);
  
            // });
          // }

        }
      }, '-=.3')
    }

    let mgStart = () => {
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle-mov', .3, {
          opacity: 0,
        })
      } else {
        mvTl.to('.s-mv__spindle-mov', .3, {
          opacity: 0,
        }, "-=0.5")
      }

      mvTl.to('.s-mv__circle-bottom', 1.5, {
        rotate: '0deg',
        opacity: 1,
        stagger: .2,
      }, '+=.3')
      
      mvTl.to('.s-mv__circle-top', 1.5, {
        rotate: '270deg',
        opacity: 1,
        stagger: .2,
      }, '-=1.5')

      mvTl.to('.s-mv__mg img', 3.5, {
        y: '0',
        x: '-0',
        stagger: .1,
        ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.032,0.446,0.062,0.678,0.104,0.782,0.13,0.82,0.164,0.827,0.204,0.836,0.958,1,1,1,1,1"),
      }, '-=1')

      mvTl.to('.s-mv__mg img', .5, {
        opacity: 1,
        stagger: .1,  
      }, '-=3.8')

      if(pcsp == 'sp') {
        mvTl.to('.s-mv__mg img', .4, {
          opacity: 0,
        }, '-=.6')
      }

      if(pcsp == 'pc') {
        mvTl.to('.s-mv__mg-txt-wrap > *', 3.2, {
          x: '0',
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.034,0.642,0.104,0.832,0.13,0.9,0.164,0.909,0.204,0.914,0.974,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      } else {
        mvTl.to('.s-mv__mg-txt-wrap > *', 3.2, {
          x: '1rem',
          ease: CustomEase.create("custom", "M0,0,C0,0,0.014,0.354,0.03,0.622,0.03,0.672,0.03,0.672,0.04,0.73,0.058,0.778,0.074,0.794,0.1,0.8,0.984,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      }
      
      mvTl.to('.s-mv__mg-txt-wrap > *', .3, {
        opacity: 1,
        stagger: .1,
      }, '<')
      
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__mg, .s-mv__mg-txt', .2, {
          opacity: 0,
        }, '-=.2')

        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '<')
      } else {
        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '-=.3')
      }
      
      mvTl.to('.s-mv__circle-top', .3, {
        rotate: '50deg',
        opacity: 0,
        stagger: .2,
      }, '-=.1')
    
      mvTl.to('.s-mv__mg-mov', .3, {
        opacity: 1,
        onStart: () => {
          $('.s-mv__mg-mov video').get(0).play();
  
          // if(onlyFuncFlgMg === false) {
          //   $('.s-mv__mg-mov video').on("ended", (e)=> {
            setTimeout(() => {

              mvTl.to('.s-mv__mg img', .1, {
                y: '-5rem',
                x: '5rem',
                opacity: 0,
              })

              if(pcsp == 'pc') {
                mvTl.to('.s-mv__mg-txt-wrap > *', .1, {
                  x: '-15rem',
                  opacity: 0,
                })
              } else {
                mvTl.to('.s-mv__mg-txt-wrap > *', .3, {
                  x: '3rem',
                  opacity: 0,
                })
                mvTl.to('.s-mv__mg-txt-wrap > *', .1, {
                  x: '-8rem',
                })
              }

              $('.s-mv__mg, .s-mv__mg-txt').removeAttr('style');

              onlyFuncFlgMg = true;

              spndleStart();
            }, 7500);
          //   });
          // }
        }
      }, '-=.3')
    }
    
    spndleStart();
  }
}
