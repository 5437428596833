// //noScroll
// class ExampleSwiper {
//   constructor(el,opt){
//     this.el = el;
//     if($("." + this.el).length > 0){
//       this.event();
//     }
//   }
//   event(){
//     var swiper = new Swiper(".js-example-swiper", {});
//   }
// }
