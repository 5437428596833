class ProductMv {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    function spanWrap() {
      const target = document.querySelectorAll('.js-span-wrap-text');
      target.forEach(function(el) {
        let nodes = [...el.childNodes];


        let spanWrapText = ""

        nodes.forEach((node) => {
          if (node.nodeType == 3) {//テキストの場合
            const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
            //spanで囲んで連結
            spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
              return acc + `<span><em>${v}</em></span>`
            }, "");
          } else {//テキスト以外
            //<br>などテキスト以外の要素をそのまま連結
            spanWrapText = spanWrapText + node.outerHTML
          }
        })

        el.innerHTML = spanWrapText
      })
    }


    spanWrap();


  }
}
