class Sds {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('.mwform-radio-field').click(function() {
      if($('.agree').prop('checked')) {
        $('.c-btn01').removeClass('c-hidden');
      } else {
        $('.c-btn01').addClass('c-hidden');
      }
    })
  }
}
