//noScroll
class Modal {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).on('click', function() {
      $(`#${$(this).attr('href')}, .c-modal__overlay`).addClass('is-active');
      return false;
    })

    $('.js-modal-close').on('click', function() {
      $('.c-modal, .c-modal__overlay').removeClass('is-active');
    })
  }
}