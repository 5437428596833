//noScroll
class FooterAco {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).click(function() {
      $(this).toggleClass('is-active').next().slideToggle();
    })

  }
}
