class SingleProduct {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    if(!document.referrer) {
      $('.js-btn-back').css({'display': 'none'});
    }
  }
}
