//form
class Form {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    // フォーム エラー分表示

    if($('.error').length) {
      $(".mw_wp_form").addClass('mw_wp_form_error').removeClass('mw_wp_form_input');
    }

    $('.js-step-btn, button[type="submit"]').click(function() {

      if(!$('.mw_wp_form_confirm, .mw_wp_form_complete, .mw_wp_form_error').length) {
        
        $('.error').remove();
  
        if($(this).parent().parent().find('.js-validation').length && !$(this).hasClass('js-step-btn-back')) {
          let failure_num = 0;
  
          $(this).parent().parent().find('.js-validation').each(function(index, el) {
            let vAttr = el.getAttribute('validation').split(',');
            vAttr.forEach(function(val, index) {
              switch(val) {
                case 'checkbox':
                  let check_num = 0;
  
                  el.querySelectorAll('input').forEach(function(el, index) {
                    (el.checked) ? check_num++ : check_num;
                  })
  
                  if(check_num == 0) {
                    el.appendChild(errorDom('必須項目です。'));
                    failure_num++;
                  }
                  break;
  
                case 'required':
                  let txt_pass_num = 0;
                  el.querySelectorAll('input, textarea').forEach(function(el) {
                    if(!el.value) {
                      txt_pass_num++;
                    }
                  })
                  if(txt_pass_num != 0) {
                    el.appendChild(errorDom('未入力です。'));
                    failure_num++;
                  }
                  break;
  
                case 'katakana':
                  if(!regExpCheck(el.querySelector('input').value, 'katakana')) {
                    el.appendChild(errorDom('カタカナで記入してください。'));
                    failure_num++;
                  }
                  break;
  
                case 'yubin・tel':
                  let tel_pass_num = 0;
                  el.querySelectorAll('input').forEach(function(el) {
                    if(!regExpCheck(el.value, 'yubin・tel')) {
                      tel_pass_num++;
                    }
                  })
                  if(tel_pass_num != 0) {
                    el.appendChild(errorDom('正しい形式で入力してください。'));
                    failure_num++;
                  }
                  break;
  
                case 'email':
                  if(el.querySelector('input').value) {
                    if(!regExpCheck(el.querySelector('input').value, 'email')) {
                      el.appendChild(errorDom('正しい形式で入力してください。'));
                      failure_num++;
                    }
                  }
                  break;
  
                case 'email_confirm':
                  if(el.querySelector('input').value) {
                    let email_comparison = [];
                    email_comparison[0] = el.querySelector('input').value;
                    email_comparison[1] = el.parentNode.previousElementSibling.querySelector('input').value;
                    if(email_comparison[0] != email_comparison[1]) {
                      el.appendChild(errorDom('同じメールアドレスを記入してください。'));
                      failure_num++;
                    }
                  }
                  break;
              }
            })
          })
  
          if(failure_num == 0) {
            if($(this)[0].nodeName != 'BUTTON') {
              $(this).parent().parent().css({'cssText': 'display: none !important;'});
              $(`.${$(this).data('step')}`).fadeIn();
              $(`.c-step li[data-step="${$(this).data('step')}"]`).addClass('is-active');
            }
          } else {
            if($(this)[0].nodeName == 'BUTTON' && $(".mw_wp_form_input").length) {
              $('body,html').animate({scrollTop:$('.js-step-anchor')[0].offsetTop - $(".js-header")[0].offsetHeight}, 500, 'swing');
              return false;
            }
          }
        } else {
          $(this).parent().parent().css({'cssText': 'display: none !important;'});
          $(`.${$(this).data('step')}`).fadeIn();
          $(`.c-step li[data-step="${$(this).data('step')}"]`).addClass('is-active');
          if($(this).hasClass('js-step-btn-back')) {
            $(`.c-step li[data-step='${$(this).data('step')}'] + li`).removeClass('is-active');
          }
        }
        if($(this)[0].nodeName != 'BUTTON') {
          $('body,html').animate({scrollTop:$('.js-step-anchor')[0].offsetTop - $(".js-header")[0].offsetHeight}, 500, 'swing');
        }
      }

    })

    function errorDom(str) {
      let dom = document.createElement('span');
      dom.classList.add('error');
      dom.textContent = str;
      return dom;
    }

    function regExpCheck(str, validation){
      let regBransh = '';

      switch(validation) {
        case 'katakana':
          regBransh = /^[ァ-ヶｦ-ﾟ　 ー\-]*$/;
          break;

        case 'yubin・tel':
          regBransh = /^[0-9０-９-]*$/;
          break;

        case 'email':
          regBransh = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
          break;
      }

      let reg = new RegExp(regBransh); //上記の正規表現を引数に渡す
      let response = reg.test(str);
      return response;
    }

    // 確認画面時に空の値を「未入力」
    $(".mw_wp_form_confirm .c-f-txt01 input, .mw_wp_form_confirm .c-f-txtarea input").each(function(index, el) {
      if(el.defaultValue == "") {
        let blank = document.createElement('span');
        blank.textContent = '未記入';
        el.before(blank);
      }
    })

    // 製品を未入力の際に非表示
    $(".p-rental-user .mw_wp_form_confirm .l-product input").each(function(index, el) {
      if(el.defaultValue == "") {
        el.parentNode.parentNode.style.display = 'none';
      }
    })

    // 住所 自動入力
    for(let i = 0; i <= 3; i++) {
      $(`[name = zip${i}]`).val(' ');
      $(`[name = addr${i}]`).val(' ');

      $(`.yubin${i}`).on('blur', function() {
        $(this).val(replaceFullToHalf($(this).val()));
        let list = [];
        $(`.yubin${i}`).each(function(index, el) {
          list[index] = el.value;
        })
        $(`[name = zip${i}]`).val(list.join(''));
        AjaxZip3.zip2addr(`zip${i}`,'',`addr${i}`,`addr${i}`);
        setTimeout(function() {
          $(`.addr${i}`).val($(`[name='addr${i}']`).val());
        }, 500)
      });
    }

    $(`[name*='電話番号'], [name*='FAX']`).on('blur', function() {
      $(this).val(replaceFullToHalf($(this).val()));
    })

    // 全角数字を半角に
    function replaceFullToHalf(str){
      return str.replace(/[！-～]/g, function(s){
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    }

    // 非該当証明書発行依頼フォーム 〜と同じ
    $('[name="ご入力者様情報01"][value="ご入力者様と同様"]').click(function() {
      $('[name="送付先_ご担当者名"]').val($('[name="入力者_ご担当者名"]').val());
      $('[name="送付先_フリガナ"]').val($('[name="入力者_フリガナ"]').val());
      $('[name="送付先_会社名"]').val($('[name="入力者_会社名"]').val());
      $('[name="送付先_営業所/支店"]').val($('[name="入力者_営業所/支店"]').val());
      $('[name="送付先_部署名"]').val($('[name="入力者_部署名"]').val());
      $('[name="送付先_役職名"]').val($('[name="入力者_役職名"]').val());
      $('[name="送付先_郵便番号[data][0]"]').val($('[name="入力者_郵便番号[data][0]"]').val());
      $('[name="送付先_郵便番号[data][1]"]').val($('[name="入力者_郵便番号[data][1]"]').val());
      $('[name="送付先_住所"]').val($('[name="入力者_住所').val());
      $('[name="送付先_FAX01"]').val($('[name="入力者_FAX01').val());
      $('[name="送付先_FAX02"]').val($('[name="入力者_FAX02').val());
      $('[name="送付先_FAX03"]').val($('[name="入力者_FAX03').val());
      $('[name="送付先_電話番号[data][0]"]').val($('[name="入力者_電話番号[data][0]').val());
      $('[name="送付先_電話番号[data][1]"]').val($('[name="入力者_電話番号[data][1]').val());
      $('[name="送付先_電話番号[data][2]"]').val($('[name="入力者_電話番号[data][2]').val());
      $('[name="送付先_メールアドレス"]').val($('[name="入力者_メールアドレス').val());
      $('[name="送付先_メールアドレス(確認)"]').val($('[name="入力者_メールアドレス(確認)').val());
    })

    $('[name="ご入力者様情報02"][value="ご入力者様と同様"]').click(function() {
      $('[name="販売店_ご担当者名"]').val($('[name="入力者_ご担当者名"]').val());
      $('[name="販売店_フリガナ"]').val($('[name="入力者_フリガナ"]').val());
      $('[name="販売店_会社名"]').val($('[name="入力者_会社名"]').val());
      $('[name="販売店_営業所/支店"]').val($('[name="入力者_営業所/支店"]').val());
      $('[name="販売店_部署名"]').val($('[name="入力者_部署名"]').val());
      $('[name="販売店_役職名"]').val($('[name="入力者_役職名"]').val());
      $('[name="販売店_郵便番号[data][0]"]').val($('[name="入力者_郵便番号[data][0]"]').val());
      $('[name="販売店_郵便番号[data][1]"]').val($('[name="入力者_郵便番号[data][1]"]').val());
      $('[name="販売店_住所"]').val($('[name="入力者_住所').val());
      $('[name="販売店_FAX01"]').val($('[name="入力者_FAX01').val());
      $('[name="販売店_FAX02"]').val($('[name="入力者_FAX02').val());
      $('[name="販売店_FAX03"]').val($('[name="入力者_FAX03').val());
      $('[name="販売店_電話番号[data][0]"]').val($('[name="入力者_電話番号[data][0]').val());
      $('[name="販売店_電話番号[data][1]"]').val($('[name="入力者_電話番号[data][1]').val());
      $('[name="販売店_電話番号[data][2]"]').val($('[name="入力者_電話番号[data][2]').val());
      $('[name="販売店_メールアドレス"]').val($('[name="入力者_メールアドレス').val());
      $('[name="販売店_メールアドレス(確認)"]').val($('[name="入力者_メールアドレス(確認)').val());
    })

    $('[name="ご入力者様情報02"][value="証明書送付先と同様"]').click(function() {
      $('[name="販売店_ご担当者名"]').val($('[name="送付先_ご担当者名"]').val());
      $('[name="販売店_フリガナ"]').val($('[name="送付先_フリガナ"]').val());
      $('[name="販売店_会社名"]').val($('[name="送付先_会社名"]').val());
      $('[name="販売店_営業所/支店"]').val($('[name="送付先_営業所/支店"]').val());
      $('[name="販売店_部署名"]').val($('[name="送付先_部署名"]').val());
      $('[name="販売店_役職名"]').val($('[name="送付先_役職名"]').val());
      $('[name="販売店_郵便番号[data][0]"]').val($('[name="送付先_郵便番号[data][0]"]').val());
      $('[name="販売店_郵便番号[data][1]"]').val($('[name="送付先_郵便番号[data][1]"]').val());
      $('[name="販売店_住所"]').val($('[name="送付先_住所').val());
      $('[name="販売店_FAX01"]').val($('[name="送付先_FAX01').val());
      $('[name="販売店_FAX02"]').val($('[name="送付先_FAX02').val());
      $('[name="販売店_FAX03"]').val($('[name="送付先_FAX03').val());
      $('[name="販売店_電話番号[data][0]"]').val($('[name="送付先_電話番号[data][0]').val());
      $('[name="販売店_電話番号[data][1]"]').val($('[name="送付先_電話番号[data][1]').val());
      $('[name="販売店_電話番号[data][2]"]').val($('[name="送付先_電話番号[data][2]').val());
      $('[name="販売店_メールアドレス"]').val($('[name="送付先_メールアドレス').val());
      $('[name="販売店_メールアドレス(確認)"]').val($('[name="送付先_メールアドレス(確認)').val());
    })

    // 先端工具サンプル依頼 ツール検索
    if($('.p-sample-shop').length) {
      $.ajax({
        url      : '../../../wp-content/uploads/csv/sample_list.csv',
        dataType : 'text',
        success  : function(resText) {
          resText = resText.split('\n');
          resText.forEach(function(el, index) {
            resText[index] = resText[index].split(',');
          })

          for(let i = 1; i <= 15; i++) {
            $(`[name="コード_${i}"]`).blur(function() {
              let codeEl = $(this);
              let flag = false;

              resText.forEach(function(el, index) {
                if(codeEl.val() == el[0]) {
                  flag = true;
                  $(`[name="品名_${i}"]`).val(el[1]);
                }
              })

              if(flag == false) {
                alert('コードNoを確認してください');
                codeEl.val('');
              }
            })
          }
        },
        error: function () {
          console.log('エラーが起きました');
        },
      });
    }
  }
}
