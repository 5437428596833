//noScroll
class ProductSwitch {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el + " div").click(function() {
      let index_num = $(this).index() + 1;
      $(".js-product-switch div").removeClass('is-active');
      $(".js-product-list figure").css({'display': 'none'}).removeClass("is-active");
      $(this).addClass('is-active');
      $(".js-product-list figure:nth-of-type(" + index_num + ")").fadeIn("is-active");
    })
  }
}
