class ArchiveCase {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    let linkEle = $('.wp-pagenavi a');
    let attr;
    let page;
    let connect;
    linkEle.each((index, el) => {
      page = "";
      attr = "";
      connect = "";
      attr = el.getAttribute('href');
      attr = attr.split('/');

      if (!attr.includes('page')) {
        let rep = attr.pop().replace(/[?&]page=[0-9]*/, '');
        attr.push(rep);
      }

      attr.forEach((el, index) => {
        if(el === 'page') {
          if(attr[index + 2].length) {
            let cat = attr[index + 2].split(/[?&]/);
            console.log(cat);
            if(cat.length >= 3 || cat[1].indexOf('page') < 0) {
              connect = '&';
            } else {
              connect = '?';
            }
          } else {
            connect = '?';
          }
          attr[index + 2] = attr[index + 2].replace(/[?&]page=[0-9]*/, '');
          page = attr.splice(index, 2);
        }
      })

      attr = attr.join('/');
      if(page) {
        attr = attr + connect + page.join('=');
      }
      el.setAttribute('href', attr);
    });

  }
}
