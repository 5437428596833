class Sample {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(".l-sample__add").on({
      click: function() {
        $(".l-sample__content.is-hidden")[0].classList.remove('is-hidden');
        if(!$(".l-sample__content.is-hidden").length) {
          $(this).css({'display': 'none'});
        }
      }
    })

    let el_hidden = () => {
      let el = $(".l-sample__content");
      el.each((index, el) => {
        if(!el.querySelector('[name*="コード"]').value.length && !el.querySelector('[name*="品名"]').value.length) {
          el.classList.add('is-hidden');
        } else {
          el.classList.remove('is-hidden');
        }
      })
    }

    if($(".mw_wp_form_confirm").length) {
      el_hidden();
    } else if($(".mw_wp_form_input").length && $("[name='コード_1']").val().length) {
      el_hidden();
    }
  }
}