class Beginner {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    this.options = {
      root: null,
      rootMargin: "-50% 0%",
      threshold: 0,
    };

    const observerEnter = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const item = entry.target;
        $(".js-sticky a").removeClass("is-active");
        $("[href='#" + entry.target.getAttribute("id") + "'").addClass(
          "is-active"
        );
      });
    }, this.options);
    
    this.inviewItem = document.querySelectorAll(".observer_ele");
    this.inviewItem.forEach((e) => {
      observerEnter.observe(e);
    });

    $(".js-sticky a").on({
      click: function() {
        let target = $(this);
        setTimeout(function() {
          $(".js-sticky a").removeClass('is-active');
          target.addClass('is-active');
        }, 500)
      }
    });

  }
}
