//ProductSize
class ProductSize {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('.js-sizeSort').click(function() {
      $('.js-sizeSort').removeClass('is-active');
      $(this).addClass('is-active');
      $('.l-lineup__el').each((index, el) => {
        let attr = el.getAttribute('data-size').split(',');
        let flag = null;
        // console.log(attr[0]);
        // console.log(attr.indexOf($(this)[0].innerText));
        if(attr.indexOf($(this)[0].innerText) != 0) {
          el.style.display = 'none';
          // el.parentElement.style.display = 'none';
          // el.parentNode.previousElementSibling.style.display = "none";
        } else {
          el.style.display = 'flex';
          // el.parentElement.style.display = 'flex';
          // el.parentNode.previousElementSibling.style.display = "block";
        }
      })



      $('body,html').animate({scrollTop: `${$('#anchor-article')[0].offsetTop - $('.js-header')[0].offsetHeight}`}, 500, 'swing');
    })
  }
}
