class ArchiveCase {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    let linkEle = $('.wp-pagenavi a');
    let attr;
    let page;
    let connect;
    linkEle.each((index, el) => {
      page = "";
      attr = "";
      connect = "";
      attr = el.getAttribute('href');
      attr = attr.split('/');

      if (!attr.includes('page')) {
        let rep = attr.pop().replace(/[?&]page=[0-9]*/, '');
        attr.push(rep);
      }

      attr.forEach((el, index) => {
        if(el === 'page') {
          if(attr[index + 2].length) {
            let cat = attr[index + 2].split(/[?&]/);
            console.log(cat);
            if(cat.length >= 3 || cat[1].indexOf('page') < 0) {
              connect = '&';
            } else {
              connect = '?';
            }
          } else {
            connect = '?';
          }
          attr[index + 2] = attr[index + 2].replace(/[?&]page=[0-9]*/, '');
          page = attr.splice(index, 2);
        }
      })

      attr = attr.join('/');
      if(page) {
        attr = attr + connect + page.join('=');
      }
      el.setAttribute('href', attr);
    });

  }
}

class ArchiveManual {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('.js-hvrImg').on({
      'mouseenter': function() {
        let hvrEl = $(this).attr('data-class');

        $('.js-img img').each(function(index, el) {
          if(el.getAttribute('data-class') == hvrEl) {
            gsap.to(el, .1, {
              opacity: 1,
            })
          }
        })
      },
      'mouseleave': function() {
        let hvrEl = $(this).attr('data-class');

        $('.js-img img').each(function(index, el) {
          if(el.getAttribute('data-class') == hvrEl) {
            gsap.to(el, .1, {
              opacity: 0,
            })
          }
        })
      }
    })
  }
}

class Beginner {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    this.options = {
      root: null,
      rootMargin: "-50% 0%",
      threshold: 0,
    };

    const observerEnter = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const item = entry.target;
        $(".js-sticky a").removeClass("is-active");
        $("[href='#" + entry.target.getAttribute("id") + "'").addClass(
          "is-active"
        );
      });
    }, this.options);
    
    this.inviewItem = document.querySelectorAll(".observer_ele");
    this.inviewItem.forEach((e) => {
      observerEnter.observe(e);
    });

    $(".js-sticky a").on({
      click: function() {
        let target = $(this);
        setTimeout(function() {
          $(".js-sticky a").removeClass('is-active');
          target.addClass('is-active');
        }, 500)
      }
    });

  }
}

//DetailSearch
class DetailSearch {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(".js-all").on('click', function() {
      if($(this).find('input').prop('checked') == true) {
        $(this).siblings().find('input').prop('checked', true);
      } else {
        $(this).siblings().find('input').prop('checked', false);
      }
    })

    $(".js-individual").on('click', function() {
      $(this).siblings('.js-all').find('input').prop('checked', false);
    })

    $('.c-s-sort-wrap__check').each(function(index, el){
      let check_el = $(this).find('.js-individual input').length;
      let check_num = 0;
      $(this).find('.js-individual input').each(function(index, el) {
        if(el.checked) {
          check_num++;
        }
      })

      if(check_el === check_num && check_el != 0) {
        $(this).find('.js-all input').prop('checked', true);
      }
    });

    $('.js-master').click(function() {
      $("[name='master_cat']").val($(this).val());
    })

    $("#anchor-article.js-inview").addClass('is-inview');

    var el_num = $(".c-product-navArea-el").length;

    switch(el_num) {
      case 3:
        $("#anchor-article").addClass('u-mt-sp-17');
        break;

      case 4:
        $("#anchor-article").addClass('u-mt-sp-23');
        break;

      case 5:
        $("#anchor-article").addClass('u-mt-sp-28');
        break;

      default:
        break;
    }
  } 
}

class Catalogue {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    if($('.js-tab').length) {
      $('.js-tab button').on('click', function() {
        $('.js-tab button').removeClass('is-active');
        $(this).addClass('is-active');
        $('.js-tabInner').css({'display': 'none'});
        $('.js-tabInner').eq($(this).index()).fadeIn();
      })
    }
  }
}

class Company {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    var img01 = new Swiper(".img01", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next01",
        prevEl: ".prev01",
      },
    });
    var img02 = new Swiper(".img02", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next02",
        prevEl: ".prev02",
      },
    });
    var img03 = new Swiper(".img03", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next03",
        prevEl: ".prev03",
      },
    });
    var img04 = new Swiper(".img04", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next04",
        prevEl: ".prev04",
      },
    });
    var img05 = new Swiper(".img05", {
      autoplay: {
        delay: 4000,
      },
      slidesPerView: 1,
      // spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction" /* この行を追加 */
      },
      navigation: {
        nextEl: ".next05",
        prevEl: ".prev05",
      },
    });
  }
}

class Faq {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    var el_num = $(".c-product-navArea-el").length;

    switch(el_num) {
      case 3:
        $(".p-faq__container").addClass('u-mt-sp-17');
        break;

      case 4:
        $(".p-faq__container").addClass('u-mt-sp-23');
        break;

      case 5:
        $(".p-faq__container").addClass('u-mt-sp-28');
        break;

      default:
        break;
    }
  }
}

// globals
const e_root = document.querySelector(':root');

window.addEventListener('load', () => {
  e_root.style.setProperty('--firstViewHeight', `${window.innerHeight}px`);
  e_root.style.setProperty('--vw', `${window.innerWidth}px`);
})

window.addEventListener('resize', () => {
  e_root.style.setProperty('--vw', `${window.innerWidth}px`);
})
//ProductSize
class ProductSize {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('.js-sizeSort').click(function() {
      $('.js-sizeSort').removeClass('is-active');
      $(this).addClass('is-active');
      $('.l-lineup__el').each((index, el) => {
        let attr = el.getAttribute('data-size').split(',');
        let flag = null;
        // console.log(attr[0]);
        // console.log(attr.indexOf($(this)[0].innerText));
        if(attr.indexOf($(this)[0].innerText) != 0) {
          el.style.display = 'none';
          // el.parentElement.style.display = 'none';
          // el.parentNode.previousElementSibling.style.display = "none";
        } else {
          el.style.display = 'flex';
          // el.parentElement.style.display = 'flex';
          // el.parentNode.previousElementSibling.style.display = "block";
        }
      })



      $('body,html').animate({scrollTop: `${$('#anchor-article')[0].offsetTop - $('.js-header')[0].offsetHeight}`}, 500, 'swing');
    })
  }
}

class ProductMv {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    function spanWrap() {
      const target = document.querySelectorAll('.js-span-wrap-text');
      target.forEach(function(el) {
        let nodes = [...el.childNodes];


        let spanWrapText = ""

        nodes.forEach((node) => {
          if (node.nodeType == 3) {//テキストの場合
            const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
            //spanで囲んで連結
            spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
              return acc + `<span><em>${v}</em></span>`
            }, "");
          } else {//テキスト以外
            //<br>などテキスト以外の要素をそのまま連結
            spanWrapText = spanWrapText + node.outerHTML
          }
        })

        el.innerHTML = spanWrapText
      })
    }


    spanWrap();


  }
}

class Sample {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $(".l-sample__add").on({
      click: function() {
        $(".l-sample__content.is-hidden")[0].classList.remove('is-hidden');
        if(!$(".l-sample__content.is-hidden").length) {
          $(this).css({'display': 'none'});
        }
      }
    })

    let el_hidden = () => {
      let el = $(".l-sample__content");
      el.each((index, el) => {
        if(!el.querySelector('[name*="コード"]').value.length && !el.querySelector('[name*="品名"]').value.length) {
          el.classList.add('is-hidden');
        } else {
          el.classList.remove('is-hidden');
        }
      })
    }

    if($(".mw_wp_form_confirm").length) {
      el_hidden();
    } else if($(".mw_wp_form_input").length && $("[name='コード_1']").val().length) {
      el_hidden();
    }
  }
}
class Sds {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $('.mwform-radio-field').click(function() {
      if($('.agree').prop('checked')) {
        $('.c-btn01').removeClass('c-hidden');
      } else {
        $('.c-btn01').addClass('c-hidden');
      }
    })
  }
}

class SingleProduct {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    if(!document.referrer) {
      $('.js-btn-back').css({'display': 'none'});
    }
  }
}

class Top {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    function spanWrap() {
      const target = document.querySelectorAll('.js-span-wrap-text');
      target.forEach(function(el) {
        
        let nodes = [...el.childNodes];
        let spanWrapText = ""

        nodes.forEach((node) => {
          if (node.nodeType == 3) {//テキストの場合
            const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
            //spanで囲んで連結
            spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
              return acc + `<span><em>${v}</em></span>`
            }, "");
          } else {//テキスト以外
            //<br>などテキスト以外の要素をそのまま連結
            spanWrapText = spanWrapText + node.outerHTML
          }
        })

        el.innerHTML = spanWrapText
      })
    }

    spanWrap();

    // ------------------
    // モーター スライダーアニメーション
    // ------------------
    const sliderTl01 = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: '.js-motor-slider01', // スクロールイベントの発火条件となるセレクタ
        start: 'top bottom', //スクロールイベントの開始地点
        end: 'bottom top', //スクロールイベントの終了地点
        // 以下、onイベント
        onEnter: () => {
          sliderTl01.play()
        },
        onEnterBack: () => {
          sliderTl01.play()
        },
        onLeaveBack: () => {
          sliderTl01.pause()
        },
        onLeave: () => {
          sliderTl01.pause()
        }
      }
    });

    const sliderTl02 = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: '.js-motor-slider02', // スクロールイベントの発火条件となるセレクタ
        start: 'top bottom', //スクロールイベントの開始地点
        end: 'bottom top', //スクロールイベントの終了地点
        // 以下、onイベント
        onEnter: () => {
          sliderTl02.play()
        },
        onEnterBack: () => {
          sliderTl02.play()
        },
        onLeaveBack: () => {
          sliderTl02.pause()
        },
        onLeave: () => {
          sliderTl02.pause()
        }
      }
    });

    let distance = null;
    window.matchMedia && window.matchMedia('(max-device-width: 767px)').matches ? distance = '5' : distance = '15';

    for(var i = 1; i < 15; i++) {
      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 1,
      }, '<');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '+=3');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '-=0.9');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 0,
      }, '<');
    }

    for(var i = 1; i < 15; i++) {
      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 1,
      }, '<');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '+=3');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '-=0.9');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 0,
      }, '<');
    }

    // ------------------
    // ローディング アニメーション
    // ------------------

    setCookie('visit', 'true', '1')

    if($(".s-loading").length) {
      gsap.to('.s-loading .loading_bar', 3, {
        width: "100%",
      })

      $("html").addClass('is-hidden');
      $(".l-header, .l-header-overlay").css({'transform': 'translateY(-100%)'})
      $(".s-mv-news").css({'transform': 'translateX(-100%)'})

      const tl = gsap.timeline();

      tl.to(".s-loading .second, .s-loading .first", 1, {
        x: "0%",
        y: "0%",
        opacity: 1,
      });

      tl.to(".s-loading .second", {
        x: "0%",
        y: "0%",
        opacity: 1,
      });

      tl.to(".s-loading .third", 1, {
        x: "0%",
        y: "0%",
        opacity: 1,
      }, "-=.2");

      tl.to(".s-loading svg", 1, {
        scale: 3,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.128,-0.087,0.178,0,0.222,0.078,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
      }, "+=1.5");

      tl.to(".s-loading", 1, {
        opacity: 0,
        onComplete: function() {
          $(".s-loading").css({'pointer-events': 'none'});
          $("html").removeClass('is-hidden');
        }
      }, "-=0.5");

      tl.to(".s-mv__ttl em", 1, {
        y: 0,
        stagger: 0.03,
        onStart: () => {
          $('.s-mv__ttl').css({'opacity': '1'})
        },
      })

      tl.to(".l-header, .l-header-overlay", .5, {
        y: 0,
      })

      tl.to(".s-mv-news", .5, {
        x: 0,
      }, '<')
    }

    // ------------------
    // PRODUCT スライダー
    // ------------------

    new Swiper(".topMoterSwiper", {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      pagination: {
        el: '.s-moter__slider--num',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' + '<hr>' + '<span class="' + totalClass + '"></span>';
        }
      },
    });

    // ------------------
    // LINEUP スライダー
    // ------------------

    const msSwiper = new Swiper(".topLineUpSwiper01", {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 2.2,
      spaceBetween: 10,
      speed: 1000,
      loop: true,
      pagination: {
        el: '.s-lineUp__pagenation01',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' + '<span></span>' + '<span class="' + totalClass + '"></span>';
        }
      },
      navigation: {
        nextEl: ".topLineUpSwiper01-next",
        prevEl: ".topLineUpSwiper01-prev",
      },
      breakpoints: {
        // スライドの表示枚数：500px以上の場合
        768: {
          slidesPerView: 3.8,
          spaceBetween: 20,
        }
      }
    });

    const mgSwiper = new Swiper(".topLineUpSwiper02", {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 2.2,
      spaceBetween: 10,
      speed: 1000,
      loop: true,
      pagination: {
        el: '.s-lineUp__pagenation02',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' + '<span></span>' + '<span class="' + totalClass + '"></span>';
        }
      },
      navigation: {
        nextEl: ".topLineUpSwiper02-next",
        prevEl: ".topLineUpSwiper02-prev",
      },
      breakpoints: {
        // スライドの表示枚数：500px以上の場合
        768: {
          slidesPerView: 3.8,
          spaceBetween: 20,
        }
      }
    });

    msSwiper.autoplay.stop();
    mgSwiper.autoplay.stop();

    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }

    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          if(item.classList.contains('topLineUpSwiper01')) {
            msSwiper.autoplay.start();
          }
          if(item.classList.contains('topLineUpSwiper02')) {
            mgSwiper.autoplay.start();
          }
        }
      });
    }, options);

    let swiperEl = document.querySelectorAll('.topLineUpSwiper01, .topLineUpSwiper02');

    swiperEl.forEach((e)=>{
      observerEnter.observe(e);
    });
    
    // ------------------
    // COLUMN スライダー
    // ------------------

    let swiper, swiperbool;
    const breakPoint = 768;

    if (breakPoint > window.innerWidth) {
      swiperbool = false;
    } else {
      createSwiper();
      swiperbool = true;
    }

     window.addEventListener('resize', () => {
     if (breakPoint > window.innerWidth && swiperbool) {
       swiper.destroy(false, true);
       swiperbool = false;
     } else if (breakPoint <= window.innerWidth && !(swiperbool)) {
       createSwiper();
       swiperbool = true;
     }
    }, false);

    function createSwiper() {
      swiper = new Swiper(".topColumnSwiper", {
        pauseOnMouseEnter: true,
        disableOnInteraction: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        speed: 1000,
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: ".topColumnSwiper-prev",
          prevEl: ".topColumnSwiper-next",
        },
      });
    }
  }
}

//noScroll
class Accordion {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    /**　親要素にjs-open設置 **/
    $('.p-aCase .c-s-sort-inner').show();
    $('.js-open').click(function(){
			$(this).toggleClass('is-open');
			$(this).next().slideToggle(300);
		});
  }
}

var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
//noScroll
class CommonParts {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){

    // ---------------------------
    // PC時 検索
    // ---------------------------

    $(".js-search").on({
      mouseover: function() {
        $(this).find('.gsc-input').addClass('is-active');
      },
      mouseout: function() {
        $(this).find('.gsc-input').removeClass('is-active');
      }
    })

    // ---------------------------
    // SP時 ハンバーガーメニュー 開閉
    // ---------------------------

    const body = document.getElementsByTagName('html')[0];
    const btn = document.querySelector('.js-h-trigger');
    let scrollTop = 0;
    
    btn.addEventListener('click', () => {
      $(".js-header").toggleClass('is-open');
      if (body.classList.contains('is-hidden')) {
        // スクロール開放
        body.style.top = '';
        body.classList.remove('is-hidden');
        window.scrollTo(0, scrollTop);
      } else {
        // スクロール禁止
        scrollTop = window.scrollY;
        body.style.top = (scrollTop * -1) + 'px';
        body.classList.add('is-hidden');
      }
    });

    // ---------------------------
    // SP時 ハンバーガーメニュー中身 アコーディオン
    // ---------------------------

    $(".js-h-aco").click(function() {
      $(this).next().next().slideToggle();
      $(this).next().toggleClass('is-active');
    })

    // ---------------------------
    // PC時 メガメニュー 開閉
    // ---------------------------

    $(".js-h-mega").on({
      mouseover: function() {
        let mega_attr = $(this).data("mega");
        $(`[data-mega=${mega_attr}]`).addClass('is-active');
        if($('.h-mv').length) {
          $('html').addClass('h-mv-mega');
        }
      },
      mouseout: function() {
        let mega_attr = $(this).data("mega");
        $(`[data-mega=${mega_attr}]`).removeClass('is-active');
        if($('.h-mv').length) {
          $('html').removeClass('h-mv-mega');
        }
      }
    })

    let time = null;
    let wTop = $(window)[0].scrollY;
    let hHeight = null;
    let mvHeight = '';
    if($(".p-top .s-mv").length) {
      mvHeight = $(".s-mv")[0].offsetTop + $(".s-mv")[0].offsetHeight;
    }

    $(window).scroll(function() {
      clearTimeout(time);
      time = setTimeout(() => {
        if(wTop - $(window)[0].scrollY >= 0 || $(window)[0].scrollY < 20 ) {
          hHeight = '0%';
          $('html').removeClass('h-hdn');
        } else {
          hHeight = '-100%';
          $('html').addClass('h-hdn');
        }
        $('.js-header__wrap, .l-header-overlay__wrap').css({'transform':`translateY(${hHeight})`});
        wTop = $(window)[0].scrollY;

        if($(".p-top .s-mv").length) {
          if(mvHeight < $(this).scrollTop()) {
            $('html').removeClass('h-mv');
          } else {
            $('html').addClass('h-mv');
          }
        }
      }, 50);
    })

    // ---------------------------
    // サイト内検索 プレースホルダー
    // ---------------------------
    $('.gsc-control-cse input').attr('placeholder', 'サイト内検索')
  }
}

// 参考サイト
// https://hacknote.jp/archives/15289/

// Cookie関係の関数
function setCookie( cookieName, cookieValue, days ){
		var dateObject = new Date();
		dateObject.setTime( dateObject.getTime() + ( days*24*60*60*1000 ) );
		var expires = "expires=" + dateObject.toGMTString();
		document.cookie = cookieName + "=" + cookieValue + "; " + expires;
}
function getCookie(cookieName ){
	var cookies = document.cookie.split('; ');
	for(var i=0; i < cookies.length; i++ ){
		var cookie = cookies[i].trim().split('=');
		if(cookie[0] == cookieName ){
			return cookie[1];
		}
	}
	return "";
}

function deleteCookie( cookieName ){
	document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
}
//noScroll
class Cursor {
  constructor(){
    const mouseStalker = document.querySelector('.cursor')
    let msPos = {
      // マウスストーカーの位置
      s: {
        x: document.documentElement.clientWidth / 2,
        y: document.documentElement.clientHeight / 2
      },
      // マウスポインターの位置
      m: {
        x: document.documentElement.clientWidth / 2,
        y: document.documentElement.clientHeight / 2
      }
    };
    
    // マウスストーカーをactiveにする
    if (window.matchMedia( "(pointer: fine)" ).matches) {
      document.addEventListener ("mousemove", msActivate);
    }
    function msActivate() {
      document.removeEventListener ("mousemove", msActivate);
      // mouseの位置セット
      document.addEventListener('mousemove', function(e){
        msPos.m.x = e.clientX;
        msPos.m.y = e.clientY;
      });
      // アニメーション開始
      requestAnimationFrame(msPosUpdate);
    }
    
    // マウスストーカーを動かす関数
    function msPosUpdate() {
      msPos.s.x += (msPos.m.x - msPos.s.x) * 0.1;
      msPos.s.y += (msPos.m.y - msPos.s.y) * 0.1;
      const x = Math.round(msPos.s.x * 10) / 10;
      const y = Math.round(msPos.s.y * 10) / 10;
      mouseStalker.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)';
      requestAnimationFrame(msPosUpdate);
    }
    
    // hover時にclass追加
    const stalkerLinkObj = document.querySelectorAll('.cursor_hover');
    for (let i = 0; i < stalkerLinkObj.length; i++) {
      stalkerLinkObj[i].addEventListener('mouseover', function(){
        mouseStalker.classList.add('is-active');
        if(stalkerLinkObj[i].classList.contains('black')) {
          mouseStalker.classList.add('black');
        }
      });
      stalkerLinkObj[i].addEventListener('mouseout', function(){
        mouseStalker.classList.remove('is-active');
        mouseStalker.classList.remove('black');
      });
    }
  }
}

// //noScroll
// class ExampleSwiper {
//   constructor(el,opt){
//     this.el = el;
//     if($("." + this.el).length > 0){
//       this.event();
//     }
//   }
//   event(){
//     var swiper = new Swiper(".js-example-swiper", {});
//   }
// }

//noScroll
class FooterAco {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).click(function() {
      $(this).toggleClass('is-active').next().slideToggle();
    })

  }
}

//form
class Form {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    // フォーム エラー分表示

    if($('.error').length) {
      $(".mw_wp_form").addClass('mw_wp_form_error').removeClass('mw_wp_form_input');
    }

    $('.js-step-btn, button[type="submit"]').click(function() {

      if(!$('.mw_wp_form_confirm, .mw_wp_form_complete, .mw_wp_form_error').length) {
        
        $('.error').remove();
  
        if($(this).parent().parent().find('.js-validation').length && !$(this).hasClass('js-step-btn-back')) {
          let failure_num = 0;
  
          $(this).parent().parent().find('.js-validation').each(function(index, el) {
            let vAttr = el.getAttribute('validation').split(',');
            vAttr.forEach(function(val, index) {
              switch(val) {
                case 'checkbox':
                  let check_num = 0;
  
                  el.querySelectorAll('input').forEach(function(el, index) {
                    (el.checked) ? check_num++ : check_num;
                  })
  
                  if(check_num == 0) {
                    el.appendChild(errorDom('必須項目です。'));
                    failure_num++;
                  }
                  break;
  
                case 'required':
                  let txt_pass_num = 0;
                  el.querySelectorAll('input, textarea').forEach(function(el) {
                    if(!el.value) {
                      txt_pass_num++;
                    }
                  })
                  if(txt_pass_num != 0) {
                    el.appendChild(errorDom('未入力です。'));
                    failure_num++;
                  }
                  break;
  
                case 'katakana':
                  if(!regExpCheck(el.querySelector('input').value, 'katakana')) {
                    el.appendChild(errorDom('カタカナで記入してください。'));
                    failure_num++;
                  }
                  break;
  
                case 'yubin・tel':
                  let tel_pass_num = 0;
                  el.querySelectorAll('input').forEach(function(el) {
                    if(!regExpCheck(el.value, 'yubin・tel')) {
                      tel_pass_num++;
                    }
                  })
                  if(tel_pass_num != 0) {
                    el.appendChild(errorDom('正しい形式で入力してください。'));
                    failure_num++;
                  }
                  break;
  
                case 'email':
                  if(el.querySelector('input').value) {
                    if(!regExpCheck(el.querySelector('input').value, 'email')) {
                      el.appendChild(errorDom('正しい形式で入力してください。'));
                      failure_num++;
                    }
                  }
                  break;
  
                case 'email_confirm':
                  if(el.querySelector('input').value) {
                    let email_comparison = [];
                    email_comparison[0] = el.querySelector('input').value;
                    email_comparison[1] = el.parentNode.previousElementSibling.querySelector('input').value;
                    if(email_comparison[0] != email_comparison[1]) {
                      el.appendChild(errorDom('同じメールアドレスを記入してください。'));
                      failure_num++;
                    }
                  }
                  break;
              }
            })
          })
  
          if(failure_num == 0) {
            if($(this)[0].nodeName != 'BUTTON') {
              $(this).parent().parent().css({'cssText': 'display: none !important;'});
              $(`.${$(this).data('step')}`).fadeIn();
              $(`.c-step li[data-step="${$(this).data('step')}"]`).addClass('is-active');
            }
          } else {
            if($(this)[0].nodeName == 'BUTTON' && $(".mw_wp_form_input").length) {
              $('body,html').animate({scrollTop:$('.js-step-anchor')[0].offsetTop - $(".js-header")[0].offsetHeight}, 500, 'swing');
              return false;
            }
          }
        } else {
          $(this).parent().parent().css({'cssText': 'display: none !important;'});
          $(`.${$(this).data('step')}`).fadeIn();
          $(`.c-step li[data-step="${$(this).data('step')}"]`).addClass('is-active');
          if($(this).hasClass('js-step-btn-back')) {
            $(`.c-step li[data-step='${$(this).data('step')}'] + li`).removeClass('is-active');
          }
        }
        if($(this)[0].nodeName != 'BUTTON') {
          $('body,html').animate({scrollTop:$('.js-step-anchor')[0].offsetTop - $(".js-header")[0].offsetHeight}, 500, 'swing');
        }
      }

    })

    function errorDom(str) {
      let dom = document.createElement('span');
      dom.classList.add('error');
      dom.textContent = str;
      return dom;
    }

    function regExpCheck(str, validation){
      let regBransh = '';

      switch(validation) {
        case 'katakana':
          regBransh = /^[ァ-ヶｦ-ﾟ　 ー\-]*$/;
          break;

        case 'yubin・tel':
          regBransh = /^[0-9０-９-]*$/;
          break;

        case 'email':
          regBransh = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
          break;
      }

      let reg = new RegExp(regBransh); //上記の正規表現を引数に渡す
      let response = reg.test(str);
      return response;
    }

    // 確認画面時に空の値を「未入力」
    $(".mw_wp_form_confirm .c-f-txt01 input, .mw_wp_form_confirm .c-f-txtarea input").each(function(index, el) {
      if(el.defaultValue == "") {
        let blank = document.createElement('span');
        blank.textContent = '未記入';
        el.before(blank);
      }
    })

    // 製品を未入力の際に非表示
    $(".p-rental-user .mw_wp_form_confirm .l-product input").each(function(index, el) {
      if(el.defaultValue == "") {
        el.parentNode.parentNode.style.display = 'none';
      }
    })

    // 住所 自動入力
    for(let i = 0; i <= 3; i++) {
      $(`[name = zip${i}]`).val(' ');
      $(`[name = addr${i}]`).val(' ');

      $(`.yubin${i}`).on('blur', function() {
        $(this).val(replaceFullToHalf($(this).val()));
        let list = [];
        $(`.yubin${i}`).each(function(index, el) {
          list[index] = el.value;
        })
        $(`[name = zip${i}]`).val(list.join(''));
        AjaxZip3.zip2addr(`zip${i}`,'',`addr${i}`,`addr${i}`);
        setTimeout(function() {
          $(`.addr${i}`).val($(`[name='addr${i}']`).val());
        }, 500)
      });
    }

    $(`[name*='電話番号'], [name*='FAX']`).on('blur', function() {
      $(this).val(replaceFullToHalf($(this).val()));
    })

    // 全角数字を半角に
    function replaceFullToHalf(str){
      return str.replace(/[！-～]/g, function(s){
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    }

    // 非該当証明書発行依頼フォーム 〜と同じ
    $('[name="ご入力者様情報01"][value="ご入力者様と同様"]').click(function() {
      $('[name="送付先_ご担当者名"]').val($('[name="入力者_ご担当者名"]').val());
      $('[name="送付先_フリガナ"]').val($('[name="入力者_フリガナ"]').val());
      $('[name="送付先_会社名"]').val($('[name="入力者_会社名"]').val());
      $('[name="送付先_営業所/支店"]').val($('[name="入力者_営業所/支店"]').val());
      $('[name="送付先_部署名"]').val($('[name="入力者_部署名"]').val());
      $('[name="送付先_役職名"]').val($('[name="入力者_役職名"]').val());
      $('[name="送付先_郵便番号[data][0]"]').val($('[name="入力者_郵便番号[data][0]"]').val());
      $('[name="送付先_郵便番号[data][1]"]').val($('[name="入力者_郵便番号[data][1]"]').val());
      $('[name="送付先_住所"]').val($('[name="入力者_住所').val());
      $('[name="送付先_FAX01"]').val($('[name="入力者_FAX01').val());
      $('[name="送付先_FAX02"]').val($('[name="入力者_FAX02').val());
      $('[name="送付先_FAX03"]').val($('[name="入力者_FAX03').val());
      $('[name="送付先_電話番号[data][0]"]').val($('[name="入力者_電話番号[data][0]').val());
      $('[name="送付先_電話番号[data][1]"]').val($('[name="入力者_電話番号[data][1]').val());
      $('[name="送付先_電話番号[data][2]"]').val($('[name="入力者_電話番号[data][2]').val());
      $('[name="送付先_メールアドレス"]').val($('[name="入力者_メールアドレス').val());
      $('[name="送付先_メールアドレス(確認)"]').val($('[name="入力者_メールアドレス(確認)').val());
    })

    $('[name="ご入力者様情報02"][value="ご入力者様と同様"]').click(function() {
      $('[name="販売店_ご担当者名"]').val($('[name="入力者_ご担当者名"]').val());
      $('[name="販売店_フリガナ"]').val($('[name="入力者_フリガナ"]').val());
      $('[name="販売店_会社名"]').val($('[name="入力者_会社名"]').val());
      $('[name="販売店_営業所/支店"]').val($('[name="入力者_営業所/支店"]').val());
      $('[name="販売店_部署名"]').val($('[name="入力者_部署名"]').val());
      $('[name="販売店_役職名"]').val($('[name="入力者_役職名"]').val());
      $('[name="販売店_郵便番号[data][0]"]').val($('[name="入力者_郵便番号[data][0]"]').val());
      $('[name="販売店_郵便番号[data][1]"]').val($('[name="入力者_郵便番号[data][1]"]').val());
      $('[name="販売店_住所"]').val($('[name="入力者_住所').val());
      $('[name="販売店_FAX01"]').val($('[name="入力者_FAX01').val());
      $('[name="販売店_FAX02"]').val($('[name="入力者_FAX02').val());
      $('[name="販売店_FAX03"]').val($('[name="入力者_FAX03').val());
      $('[name="販売店_電話番号[data][0]"]').val($('[name="入力者_電話番号[data][0]').val());
      $('[name="販売店_電話番号[data][1]"]').val($('[name="入力者_電話番号[data][1]').val());
      $('[name="販売店_電話番号[data][2]"]').val($('[name="入力者_電話番号[data][2]').val());
      $('[name="販売店_メールアドレス"]').val($('[name="入力者_メールアドレス').val());
      $('[name="販売店_メールアドレス(確認)"]').val($('[name="入力者_メールアドレス(確認)').val());
    })

    $('[name="ご入力者様情報02"][value="証明書送付先と同様"]').click(function() {
      $('[name="販売店_ご担当者名"]').val($('[name="送付先_ご担当者名"]').val());
      $('[name="販売店_フリガナ"]').val($('[name="送付先_フリガナ"]').val());
      $('[name="販売店_会社名"]').val($('[name="送付先_会社名"]').val());
      $('[name="販売店_営業所/支店"]').val($('[name="送付先_営業所/支店"]').val());
      $('[name="販売店_部署名"]').val($('[name="送付先_部署名"]').val());
      $('[name="販売店_役職名"]').val($('[name="送付先_役職名"]').val());
      $('[name="販売店_郵便番号[data][0]"]').val($('[name="送付先_郵便番号[data][0]"]').val());
      $('[name="販売店_郵便番号[data][1]"]').val($('[name="送付先_郵便番号[data][1]"]').val());
      $('[name="販売店_住所"]').val($('[name="送付先_住所').val());
      $('[name="販売店_FAX01"]').val($('[name="送付先_FAX01').val());
      $('[name="販売店_FAX02"]').val($('[name="送付先_FAX02').val());
      $('[name="販売店_FAX03"]').val($('[name="送付先_FAX03').val());
      $('[name="販売店_電話番号[data][0]"]').val($('[name="送付先_電話番号[data][0]').val());
      $('[name="販売店_電話番号[data][1]"]').val($('[name="送付先_電話番号[data][1]').val());
      $('[name="販売店_電話番号[data][2]"]').val($('[name="送付先_電話番号[data][2]').val());
      $('[name="販売店_メールアドレス"]').val($('[name="送付先_メールアドレス').val());
      $('[name="販売店_メールアドレス(確認)"]').val($('[name="送付先_メールアドレス(確認)').val());
    })

    // 先端工具サンプル依頼 ツール検索
    if($('.p-sample-shop').length) {
      $.ajax({
        url      : '../../../wp-content/uploads/csv/sample_list.csv',
        dataType : 'text',
        success  : function(resText) {
          resText = resText.split('\n');
          resText.forEach(function(el, index) {
            resText[index] = resText[index].split(',');
          })

          for(let i = 1; i <= 15; i++) {
            $(`[name="コード_${i}"]`).blur(function() {
              let codeEl = $(this);
              let flag = false;

              resText.forEach(function(el, index) {
                if(codeEl.val() == el[0]) {
                  flag = true;
                  $(`[name="品名_${i}"]`).val(el[1]);
                }
              })

              if(flag == false) {
                alert('コードNoを確認してください');
                codeEl.val('');
              }
            })
          }
        },
        error: function () {
          console.log('エラーが起きました');
        },
      });
    }
  }
}

//noScroll
class ImgParallax {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    this.para_ele = document.querySelectorAll("." + this.el);
    this.para_ele.forEach((ele, index)=> {
      if(ele.classList.contains('js-parallax--reverse')) {
        this.translateY = '20px';
      } else {
        this.translateY = '-20px';
      }
      ele.classList.add(this.el + index);
      gsap.to('.' + this.el + index + ' img', {
        translateY: this.translateY,
        scrollTrigger: {
          trigger: '.' + this.el + index, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom',
          scrub: 3,
        }
      })
    })
  }
}

//noScroll
class ImgZoom {
  constructor(){  
    //imgのIDやクラスを指定
    var $img = $("#zoomImg").imgViewer2(
      {
        //画像の揺れの改善
        onReady: function() {
          this.setZoom(2);
          this.setZoom(1);
        }
      }
    );
  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemDelay = this.el + '-eachItem-delay';
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.2 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}

//noScroll
class MapAco {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).click(function() {
      $(this).toggleClass('is-active').next().slideToggle();
    })
  }
}

//noScroll
class Modal {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).on('click', function() {
      $(`#${$(this).attr('href')}, .c-modal__overlay`).addClass('is-active');
      return false;
    })

    $('.js-modal-close').on('click', function() {
      $('.c-modal, .c-modal__overlay').removeClass('is-active');
    })
  }
}
class Mv {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    let pcsp = '';

    let onlyFuncFlgSpindle = false;
    let onlyFuncFlgMg = false;

    window.matchMedia('(max-width: 767px)').matches ? pcsp = 'sp' : pcsp = 'pc';

    const mvTl = gsap.timeline();

    if(!$('.noLoading').length) {
      mvTl.to('.s-mv__mg-mov', .3, {
        delay: 4.5,
      })
    }


    let spndleStart = () => {
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__mg-mov', .3, {
          opacity: 0,
        })
      } else {
        mvTl.to('.s-mv__mg-mov', .3, {
          opacity: 0,
        }, "-=0.5")
      }

      mvTl.to('.s-mv__circle-bottom', 1.5, {
        rotate: '0deg',
        opacity: 1,
        stagger: .2,
      })
      
      mvTl.to('.s-mv__circle-top', 1.5, {
        rotate: '270deg',
        opacity: 1,
        stagger: .2,
      }, '-=1.5')

      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle img', 3.5, {
          y: '0rem',
          x: '-0rem',
          stagger: .1,
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.072,0.832,0.144,0.966,0.158,0.984,0.174,0.989,0.204,0.99,0.976,1,1,1,1,1"),
        }, '-=1')
      } else {
        mvTl.to('.s-mv__spindle img', 3.5, {
          y: '0',
          x: '-0',
          stagger: .1,
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.072,0.832,0.144,0.966,0.158,0.984,0.174,0.989,0.204,0.99,0.976,1,1,1,1,1"),
        }, '-=1')
      
        mvTl.to('.s-mv__spindle img', .5, {
          opacity: 0,
        }, '-=.5')
      }
      
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle-txt-wrap > *', 3.2, {
          x: '0',
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.034,0.642,0.104,0.832,0.13,0.9,0.164,0.909,0.204,0.914,0.974,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      } else {
        mvTl.to('.s-mv__spindle-txt-wrap > *', 3.2, {
          x: '1rem',
          ease: CustomEase.create("custom", "M0,0,C0,0,0.014,0.354,0.03,0.622,0.03,0.672,0.03,0.672,0.04,0.73,0.058,0.778,0.074,0.794,0.1,0.8,0.984,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      }
      
      mvTl.to('.s-mv__spindle-txt-wrap > *', .3, {
        opacity: 1,
        stagger: .1,
      }, '<')
      
      // if(pcsp == 'pc') {
      //   mvTl.to('.s-mv__spindle-txt-wrap > *', .3, {
      //     opacity: 0,
      //     stagger: .1,
      //   }, '-=.8')
      // }

      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle, .s-mv__spindle-txt', .2, {
          opacity: 0,
        }, '-=.2')

        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '<')
      } else {
        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '-=.3')
      }
        
      
      mvTl.to('.s-mv__circle-top', .3, {
        rotate: '50deg',
        opacity: 0,
        stagger: .2,
      }, '-=.1')
      
      mvTl.to('.s-mv__spindle-mov', .3, {
        opacity: 1,
        onStart: () => {
          $('.s-mv__spindle-mov video').get(0).play();

          // if(onlyFuncFlgSpindle === false) {
            // $('.s-mv__spindle-mov video').on("ended", (e)=> {
            setTimeout(() => {
              if(pcsp == 'pc') {
                mvTl.to('.s-mv__spindle img', .1, {
                  y: '-120rem',
                  x: '120rem',
                  opacity: 1,
                })
              } else {
                mvTl.to('.s-mv__spindle img', .1, {
                  y: '-50rem',
                  x: '50rem',
                  opacity: 1,
                })
              }

              if(pcsp == 'pc') {
                mvTl.to('.s-mv__spindle-txt-wrap > *', .1, {
                  x: '-15rem',
                  opacity: 0,
                })
              } else {
                mvTl.to('.s-mv__spindle-txt-wrap > *', .3, {
                  x: '3rem',
                  opacity: 0,
                })
                mvTl.to('.s-mv__spindle-txt-wrap > *', .1, {
                  x: '-8rem',
                })
              }

              $('.s-mv__spindle, .s-mv__spindle-txt').removeAttr('style');

              onlyFuncFlgSpindle = true;
  
              mgStart();
            }, 7500);
  
            // });
          // }

        }
      }, '-=.3')
    }

    let mgStart = () => {
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__spindle-mov', .3, {
          opacity: 0,
        })
      } else {
        mvTl.to('.s-mv__spindle-mov', .3, {
          opacity: 0,
        }, "-=0.5")
      }

      mvTl.to('.s-mv__circle-bottom', 1.5, {
        rotate: '0deg',
        opacity: 1,
        stagger: .2,
      }, '+=.3')
      
      mvTl.to('.s-mv__circle-top', 1.5, {
        rotate: '270deg',
        opacity: 1,
        stagger: .2,
      }, '-=1.5')

      mvTl.to('.s-mv__mg img', 3.5, {
        y: '0',
        x: '-0',
        stagger: .1,
        ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.032,0.446,0.062,0.678,0.104,0.782,0.13,0.82,0.164,0.827,0.204,0.836,0.958,1,1,1,1,1"),
      }, '-=1')

      mvTl.to('.s-mv__mg img', .5, {
        opacity: 1,
        stagger: .1,  
      }, '-=3.8')

      if(pcsp == 'sp') {
        mvTl.to('.s-mv__mg img', .4, {
          opacity: 0,
        }, '-=.6')
      }

      if(pcsp == 'pc') {
        mvTl.to('.s-mv__mg-txt-wrap > *', 3.2, {
          x: '0',
          ease: CustomEase.create("custom", "M0,0,C0,0,0,0.032,0.014,0.21,0.016,0.239,0.034,0.642,0.104,0.832,0.13,0.9,0.164,0.909,0.204,0.914,0.974,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      } else {
        mvTl.to('.s-mv__mg-txt-wrap > *', 3.2, {
          x: '1rem',
          ease: CustomEase.create("custom", "M0,0,C0,0,0.014,0.354,0.03,0.622,0.03,0.672,0.03,0.672,0.04,0.73,0.058,0.778,0.074,0.794,0.1,0.8,0.984,1,1,1,1,1"),
          stagger: .1,
        }, '-=3.8')
      }
      
      mvTl.to('.s-mv__mg-txt-wrap > *', .3, {
        opacity: 1,
        stagger: .1,
      }, '<')
      
      if(pcsp == 'pc') {
        mvTl.to('.s-mv__mg, .s-mv__mg-txt', .2, {
          opacity: 0,
        }, '-=.2')

        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '<')
      } else {
        mvTl.to('.s-mv__circle-bottom', .3, {
          rotate: '180deg',
          opacity: 0,
          stagger: .2,
        }, '-=.3')
      }
      
      mvTl.to('.s-mv__circle-top', .3, {
        rotate: '50deg',
        opacity: 0,
        stagger: .2,
      }, '-=.1')
    
      mvTl.to('.s-mv__mg-mov', .3, {
        opacity: 1,
        onStart: () => {
          $('.s-mv__mg-mov video').get(0).play();
  
          // if(onlyFuncFlgMg === false) {
          //   $('.s-mv__mg-mov video').on("ended", (e)=> {
            setTimeout(() => {

              mvTl.to('.s-mv__mg img', .1, {
                y: '-5rem',
                x: '5rem',
                opacity: 0,
              })

              if(pcsp == 'pc') {
                mvTl.to('.s-mv__mg-txt-wrap > *', .1, {
                  x: '-15rem',
                  opacity: 0,
                })
              } else {
                mvTl.to('.s-mv__mg-txt-wrap > *', .3, {
                  x: '3rem',
                  opacity: 0,
                })
                mvTl.to('.s-mv__mg-txt-wrap > *', .1, {
                  x: '-8rem',
                })
              }

              $('.s-mv__mg, .s-mv__mg-txt').removeAttr('style');

              onlyFuncFlgMg = true;

              spndleStart();
            }, 7500);
          //   });
          // }
        }
      }, '-=.3')
    }
    
    spndleStart();
  }
}

//noScroll
class ProductSwitch {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el + " div").click(function() {
      let index_num = $(this).index() + 1;
      $(".js-product-switch div").removeClass('is-active');
      $(".js-product-list figure").css({'display': 'none'}).removeClass("is-active");
      $(this).addClass('is-active');
      $(".js-product-list figure:nth-of-type(" + index_num + ")").fadeIn("is-active");
    })
  }
}

//noScroll
class SmoothScroll {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    // メディアクエリがtrueかどうかをチェック
    var headerHeight = $('.js-header').outerHeight();     //fixedのヘッダーの高さを取得
    var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 500;   //スクロールのアニメーションスピード
    if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
        var target = $(urlHash.split('&')[0]);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
      }, 100);
    }
    $('a[href^="#"]').on({
      'click': function(){
        var href= $(this).attr("href");
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);   
      }
    });
  }
}
 
//noScroll
class TabSwitch {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
		$('.js-menu-tab li').on('click', function() {
			$('.js-menu-tab li').removeClass('is-active');
			$(this).addClass('is-active');
			$('.js-menu-inner').removeClass('is-active').css({'display': 'none'}).eq($(this).index()).fadeToggle();
    })

		$('.js-menu-tab_sp li').on('click', function() {
			$('.js-menu-tab_sp li').removeClass('is-active');
			$(this).addClass('is-active');
			$('.js-menu-inner_sp').removeClass('is-active').css({'display': 'none'}).eq($(this).index()).fadeToggle();
    })
  }
}


(() => {
})();

//DOMContentLoaded
$(() => {
});

//images resources loaded
$(window).on('load', () => {
  $(window).trigger('loading');
});

//after loading animation
$(window).on('loading', () => {
  let commonParts = new CommonParts();
  let mapAco = new MapAco('js-map-aco');
  let footerAco = new FooterAco('js-ftr-aco');
  let imgParallax = new ImgParallax('js-parallax');
  let inviewEvent = new InviewEvent('.js-inview');
  let productSwitch = new ProductSwitch('js-product-switch');
  let accordion = new Accordion('js-acco');
  let smoothScroll = new SmoothScroll('');
  let top = new Top('p-top');
  let faq = new Faq('p-faq');
  let mv = new Mv('p-top');
  let sample = new Sample('js-sample');
  let modal = new Modal('js-modal');
  let beginner = new Beginner('p-beginner');
  let company = new Company('p-company');
  let detailSearch = new DetailSearch('js-sort');
  let productSize = new ProductSize('p-aProduct');
  let form = new Form('mw_wp_form');
  let cursor = new Cursor();
  let tabSwitch = new TabSwitch('js-menu-tab');
  let imgZoom = new ImgZoom();
  let sds = new Sds('p-sds');
  let productMv = new ProductMv('c-product');
  let archiveCase = new ArchiveCase('p-aCase');
  let archiveManual = new ArchiveManual('p-aManual');
  let singleProduct = new SingleProduct('p-sManual');
  let catalogue = new Catalogue('p-catalogue');
  if(!$('html.en').length) {
    let scrollHint = new ScrollHint('.js-scrollable', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'スクロールできます'
      }
    });
  } else {
    let scrollHint = new ScrollHint('.js-scrollable', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'can scroll'
      }
    });
  }
});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });