class Catalogue {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    if($('.js-tab').length) {
      $('.js-tab button').on('click', function() {
        $('.js-tab button').removeClass('is-active');
        $(this).addClass('is-active');
        $('.js-tabInner').css({'display': 'none'});
        $('.js-tabInner').eq($(this).index()).fadeIn();
      })
    }
  }
}
