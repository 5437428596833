//noScroll
class TabSwitch {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
		$('.js-menu-tab li').on('click', function() {
			$('.js-menu-tab li').removeClass('is-active');
			$(this).addClass('is-active');
			$('.js-menu-inner').removeClass('is-active').css({'display': 'none'}).eq($(this).index()).fadeToggle();
    })

		$('.js-menu-tab_sp li').on('click', function() {
			$('.js-menu-tab_sp li').removeClass('is-active');
			$(this).addClass('is-active');
			$('.js-menu-inner_sp').removeClass('is-active').css({'display': 'none'}).eq($(this).index()).fadeToggle();
    })
  }
}