class Top {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    function spanWrap() {
      const target = document.querySelectorAll('.js-span-wrap-text');
      target.forEach(function(el) {
        
        let nodes = [...el.childNodes];
        let spanWrapText = ""

        nodes.forEach((node) => {
          if (node.nodeType == 3) {//テキストの場合
            const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
            //spanで囲んで連結
            spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
              return acc + `<span><em>${v}</em></span>`
            }, "");
          } else {//テキスト以外
            //<br>などテキスト以外の要素をそのまま連結
            spanWrapText = spanWrapText + node.outerHTML
          }
        })

        el.innerHTML = spanWrapText
      })
    }

    spanWrap();

    // ------------------
    // モーター スライダーアニメーション
    // ------------------
    const sliderTl01 = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: '.js-motor-slider01', // スクロールイベントの発火条件となるセレクタ
        start: 'top bottom', //スクロールイベントの開始地点
        end: 'bottom top', //スクロールイベントの終了地点
        // 以下、onイベント
        onEnter: () => {
          sliderTl01.play()
        },
        onEnterBack: () => {
          sliderTl01.play()
        },
        onLeaveBack: () => {
          sliderTl01.pause()
        },
        onLeave: () => {
          sliderTl01.pause()
        }
      }
    });

    const sliderTl02 = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: '.js-motor-slider02', // スクロールイベントの発火条件となるセレクタ
        start: 'top bottom', //スクロールイベントの開始地点
        end: 'bottom top', //スクロールイベントの終了地点
        // 以下、onイベント
        onEnter: () => {
          sliderTl02.play()
        },
        onEnterBack: () => {
          sliderTl02.play()
        },
        onLeaveBack: () => {
          sliderTl02.pause()
        },
        onLeave: () => {
          sliderTl02.pause()
        }
      }
    });

    let distance = null;
    window.matchMedia && window.matchMedia('(max-device-width: 767px)').matches ? distance = '5' : distance = '15';

    for(var i = 1; i < 15; i++) {
      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 1,
      }, '<');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '+=3');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '-=0.9');

      sliderTl01.to(`.js-motor-slider01 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 0,
      }, '<');
    }

    for(var i = 1; i < 15; i++) {
      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 1,
      }, '<');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: "0",
        y: "0",
        opacity: 1,
        ease: Expo.easeOut,
      }, '-=.8');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:first-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '+=3');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-wrap:nth-of-type(${i}) img:last-of-type`, 1, {
        x: `-${distance}rem`,
        y: `${distance}rem`,
        opacity: 0,
        ease:  Expo.easeOut,
      }, '-=0.9');

      sliderTl02.to(`.js-motor-slider02 .s-moter__slider-num span em:nth-of-type(${i})`, .5, {
        opacity: 0,
      }, '<');
    }

    // ------------------
    // ローディング アニメーション
    // ------------------

    setCookie('visit', 'true', '1')

    if($(".s-loading").length) {
      gsap.to('.s-loading .loading_bar', 3, {
        width: "100%",
      })

      $("html").addClass('is-hidden');
      $(".l-header, .l-header-overlay").css({'transform': 'translateY(-100%)'})
      $(".s-mv-news").css({'transform': 'translateX(-100%)'})

      const tl = gsap.timeline();

      tl.to(".s-loading .second, .s-loading .first", 1, {
        x: "0%",
        y: "0%",
        opacity: 1,
      });

      tl.to(".s-loading .second", {
        x: "0%",
        y: "0%",
        opacity: 1,
      });

      tl.to(".s-loading .third", 1, {
        x: "0%",
        y: "0%",
        opacity: 1,
      }, "-=.2");

      tl.to(".s-loading svg", 1, {
        scale: 3,
        ease: CustomEase.create("custom", "M0,0,C0,0,0.128,-0.087,0.178,0,0.222,0.078,0.332,0.535,0.364,0.596,0.4,0.664,0.499,0.793,0.542,0.826,0.6,0.87,0.696,0.936,0.798,0.968,0.912,1.004,1,1,1,1"),
      }, "+=1.5");

      tl.to(".s-loading", 1, {
        opacity: 0,
        onComplete: function() {
          $(".s-loading").css({'pointer-events': 'none'});
          $("html").removeClass('is-hidden');
        }
      }, "-=0.5");

      tl.to(".s-mv__ttl em", 1, {
        y: 0,
        stagger: 0.03,
        onStart: () => {
          $('.s-mv__ttl').css({'opacity': '1'})
        },
      })

      tl.to(".l-header, .l-header-overlay", .5, {
        y: 0,
      })

      tl.to(".s-mv-news", .5, {
        x: 0,
      }, '<')
    }

    // ------------------
    // PRODUCT スライダー
    // ------------------

    new Swiper(".topMoterSwiper", {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      pagination: {
        el: '.s-moter__slider--num',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' + '<hr>' + '<span class="' + totalClass + '"></span>';
        }
      },
    });

    // ------------------
    // LINEUP スライダー
    // ------------------

    const msSwiper = new Swiper(".topLineUpSwiper01", {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 2.2,
      spaceBetween: 10,
      speed: 1000,
      loop: true,
      pagination: {
        el: '.s-lineUp__pagenation01',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' + '<span></span>' + '<span class="' + totalClass + '"></span>';
        }
      },
      navigation: {
        nextEl: ".topLineUpSwiper01-next",
        prevEl: ".topLineUpSwiper01-prev",
      },
      breakpoints: {
        // スライドの表示枚数：500px以上の場合
        768: {
          slidesPerView: 3.8,
          spaceBetween: 20,
        }
      }
    });

    const mgSwiper = new Swiper(".topLineUpSwiper02", {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 2.2,
      spaceBetween: 10,
      speed: 1000,
      loop: true,
      pagination: {
        el: '.s-lineUp__pagenation02',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' + '<span></span>' + '<span class="' + totalClass + '"></span>';
        }
      },
      navigation: {
        nextEl: ".topLineUpSwiper02-next",
        prevEl: ".topLineUpSwiper02-prev",
      },
      breakpoints: {
        // スライドの表示枚数：500px以上の場合
        768: {
          slidesPerView: 3.8,
          spaceBetween: 20,
        }
      }
    });

    msSwiper.autoplay.stop();
    mgSwiper.autoplay.stop();

    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }

    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          if(item.classList.contains('topLineUpSwiper01')) {
            msSwiper.autoplay.start();
          }
          if(item.classList.contains('topLineUpSwiper02')) {
            mgSwiper.autoplay.start();
          }
        }
      });
    }, options);

    let swiperEl = document.querySelectorAll('.topLineUpSwiper01, .topLineUpSwiper02');

    swiperEl.forEach((e)=>{
      observerEnter.observe(e);
    });
    
    // ------------------
    // COLUMN スライダー
    // ------------------

    let swiper, swiperbool;
    const breakPoint = 768;

    if (breakPoint > window.innerWidth) {
      swiperbool = false;
    } else {
      createSwiper();
      swiperbool = true;
    }

     window.addEventListener('resize', () => {
     if (breakPoint > window.innerWidth && swiperbool) {
       swiper.destroy(false, true);
       swiperbool = false;
     } else if (breakPoint <= window.innerWidth && !(swiperbool)) {
       createSwiper();
       swiperbool = true;
     }
    }, false);

    function createSwiper() {
      swiper = new Swiper(".topColumnSwiper", {
        pauseOnMouseEnter: true,
        disableOnInteraction: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        speed: 1000,
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: ".topColumnSwiper-prev",
          prevEl: ".topColumnSwiper-next",
        },
      });
    }
  }
}
